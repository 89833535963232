import { Link } from 'gatsby'
import { styled } from 'linaria/react'

import { FC } from 'react'

const Main = styled.main`
	color: #232129;
	font-family: sans-serif;
	line-height: 1.5;
	max-width: 50rem;
	margin: 4rem auto;
	padding: 1rem;
`

const Heading = styled.h1`
	margin-top: 0;
	margin-bottom: 2rem;
`

const P = styled.p`
	margin-bottom: 2rem;
`

const NotFoundPage: FC = () => {
	return (
		<Main>
			<Heading>Page not found</Heading>
			<P>
				Sorry <span aria-hidden='true'>😔</span> we couldn’t find what you were looking for.
				<br />
				<Link to='/'>Go home</Link>.
			</P>
		</Main>
	)
}

export default NotFoundPage
